<template>
  <el-dialog
    :title="dialogStaus=='1'?'新增':'编辑'"
    :visible.sync="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
    class="br-dialog add-group-dialog"
    @close="handleCancel"
  >
    <el-form ref="groupManageForm" :model="dialogFrom" :rules="rules" label-width="120px">
      <el-form-item class="br-form-item-label" label="集团名称：" prop="group">
        <el-select
          v-model="dialogFrom.group"
          class="br-select-large"
          :disabled="dialogStaus=='2'"
          value-key="coreCmpUnicode"
          @change="groupNameChange"
        >
          <el-option
            v-for="(item,index) in groupNameList"
            :key="index"
            :label="item.coreCmpName"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="资金方名称：" prop="bank">
        <el-select
          v-model="dialogFrom.bank"
          class="br-select-large"
          :disabled="dialogStaus=='2'"
          value-key="fundCmpUnicode"
          @change="bankNameChange"
        >
          <el-option
            v-for="(item,index) in bankList"
            :key="index"
            :label="item.fundCmpName"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="总额度：" prop="quotaSum">
        <el-input
          v-model="dialogFrom.quotaSum"
          class="br-input-large"
          clearable
          oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          placeholder="请输入总额度"
          @input="numChange($event,'quotaSum')"
        />
      </el-form-item>
      <el-form-item v-if="dialogStaus=='2'" class="br-form-item-label" label="可用额度：" prop="quotaAbled">
        <el-input
          v-model="dialogFrom.quotaAbled"
          class="br-input-large"
          clearable
          oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          placeholder="请输入可用额度"
          @input="numChange($event,'quotaAbled')"
        />
      </el-form-item>
      <el-form-item v-if="dialogStaus=='2'" class="br-form-item-label" label="冻结额度：" prop="quotaDisabled">
        <el-input
          v-model="dialogFrom.quotaDisabled"
          class="br-input-large"
          clearable
          :disabled="dialogStaus=='2'"
          oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          placeholder="请输入动机额度"
        />
      </el-form-item>
      <el-form-item v-if="dialogStaus=='2'" class="br-form-item-label" label="已用额度：" prop="quotaUsed">
        <el-input
          v-model="dialogFrom.quotaUsed"
          class="br-input-large"
          clearable
          :disabled="dialogStaus=='2'"
          oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          placeholder="请输入已用额度"
        />
      </el-form-item>
      <el-form-item class="br-form-item-label" label="额度到期日" prop="quotaDue">
        <el-date-picker
          v-model="dialogFrom.quotaDue"
          class="br-input-large"
          type="date"
          placeholder="额度到期日"
          :picker-options="dateOptions"
          value-format="timestamp"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button class="br-btn-large br-cancel-btn" size="small" @click="handleCancel">
        取消
      </el-button>
      <el-button type="primary" class="br-btn-large br-submit-btn" size="small" :loading="btnloading" @click="handleSubmit">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { selectDistinctList, insertSelective, updateGroupSelective } from '@/api/tradeFinancing'
export default {
  props: {
    dialogStaus: {
      type: String,
      default: () => ('1')
    },
    isShowDialog: {
      type: Boolean,
      default: () => (false)
    },
    addGroupDialogData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      btnloading: false,
      dialogFrom: {},
      rules: {
        group: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择集团企业'
          }
        ],
        bank: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择资金方'
          }
        ],
        quotaSum: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入总额度'
          }
        ],
        quotaAbled: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入可用额度'
          },
          {
            validator: this.quotaAbleValidator, trigger: 'change'
          }
        ],
        quotaDisabled: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入冻结额度'
          }
        ],
        quotaUsed: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入已用额度'
          }
        ],
        quotaDue: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择额度到期日'
          }
        ]
      },
      bankList: [],
      groupNameList: [],
      dateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      }
    }
  },
  computed: {
    // bankList() {
    //   return this.$store.state.tradeFinancing.bankList
    // }
  },
  watch: {
    isShowDialog(newV) {
      this.dialogVisible = newV
      this.dialogFrom = JSON.parse(JSON.stringify(this.addGroupDialogData))
      if (!this.$store.state.tradeFinancing.bankList.length) {
        this.$store.dispatch('tradeFinancing/querBankList')
      }
      this.getDistinctList('groupNameList', '')
      if (this.dialogStaus === '2') {
        this.getDistinctList('bankList', this.dialogFrom['coreCmpUnicode'])
      }
    }
  },
  methods: {
    quotaAbleValidator(rule, value, callback) {
      this.checkQuotaAble(rule, value, callback)
    },
    checkQuotaAble(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入可用额度'))
      } else {
        const num = this.dialogFrom['quotaSum'] - this.dialogFrom['quotaUsed'] - this.dialogFrom['quotaDisabled']
        if (value > num) {
          callback(new Error('可用额度已超上限'))
        } else {
          callback()
        }
      }
    },
    bankNameChange(e) {
      if (e) {
        this.dialogFrom['bankName'] = e.fundCmpName
        this.dialogFrom['bankUnicode'] = e.fundCmpUnicode
        this.$forceUpdate()
      }
    },
    groupNameChange(e) {
      if (e) {
        this.dialogFrom['bank'] = {}
        this.dialogFrom['bankName'] = ''
        this.dialogFrom['bankUnicode'] = ''
        this.dialogFrom['groupName'] = e.coreCmpName
        this.dialogFrom['groupUnicode'] = e.coreCmpUnicode
        this.getDistinctList('bankList', e.coreCmpUnicode)
        this.$forceUpdate()
      }
    },
    numChange(e, name) {
      if (e > 0) {
        const length = e.length
        for (let i = 0; i < length; i++) {
          if (e[0] === 0) {
            e = e.substr(1)
          } else {
            break
          }
        }
      }
      this.dialogFrom[name] = e
    },
    getDistinctList(name, v) {
      let params = {}
      if (name === 'groupNameList') {
        params = {}
      }
      if (name === 'bankList') {
        params = { coreCmpUnicode: v }
      }
      selectDistinctList(params, res => {
        this[name] = res.data
      })
    },
    handleCancel() {
      this.$refs['groupManageForm'].resetFields()
      this.$emit('handleCancel', false)
    },
    handleSubmit() {
      var that = this
      const params = JSON.parse(JSON.stringify(that.dialogFrom))
      delete params.bank
      delete params.group
      this.$refs['groupManageForm'].validate(valid => {
        if (valid) {
          if (this.dialogStaus === '1') {
            // 新增
            insertSelective(params, res => {
              if (res.code === 200) {
                that.$message({
                  type: 'success',
                  message: '新增成功'
                })
                this.$emit('handleCancel', false)
                this.$emit('getDate')
              }
            })
          } else {
            // 更新
            updateGroupSelective(params, res => {
              const code = res.code
              if (code === 200) {
                that.$message({
                  type: 'success',
                  message: '编辑成功'
                })
                this.$emit('handleCancel', false)
                this.$emit('getDate')
              }
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
